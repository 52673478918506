import {useTranslation} from 'react-i18next';
import {
	useUpdateRegulatoryDocumentParagraphsMutation,
	GetCompareDocumentDataQuery,
} from '../../../hooks';
import {useUserContext} from 'authentication/UserContext';
import {GetRegulationDocument} from 'features/Regulations/hooks';
import {IComparedItem} from '../compareDocuments/compareDocuments';
import {RegulatoryDocumentParagraph, ParagraphUpdateInput} from 'types';
import {GetRegulatoryDocumentDetailsDocument} from 'features/RegulatoryDocuments/hooks/useGetRegulatoryDocumentDetails.generated';
import {useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareMutation} from 'features/RegulatoryDocuments/hooks/useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompare.generated';
import _ from 'lodash';
import {useCloneRequirement} from 'features/Requirements/hooks/useCloneRequirement';
import {
	prepareRequirementsIdsForUpdate,
	createCloneRequirements,
} from './CompareVersionPanel.utils';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {mapToRef} from 'helpers';

export type ComparisonUpdateFieldType =
	| 'VehicleCategories'
	| 'Keywords'
	| 'Categories'
	| 'DriveVariants'
	| 'Tags'
	| 'Requirements';

export type ComparisonUpdateFields = ComparisonUpdateFieldType[];

export const useComparisonUpdate = (
	selectedItems: IComparedItem[],
	newVersion: NonNullable<GetCompareDocumentDataQuery['regulatoryDocument']>,
	data: any,
) => {
	const {t} = useTranslation('features/regulatorydocuments', {
		keyPrefix: 'CompareVersionPanel',
	});
	const {isVex} = useUserContext();

	const {createCloneForMerging, getInitialVersion} = useCloneRequirement();
	const [updateParagraphs] = useUpdateRegulatoryDocumentParagraphsMutation();
	const [updateParagraphsRequirementsCompareDocument] =
		useAssignRegulatoryDocumentParagraphsRequirementsDocumentCompareMutation();

	const [allFields, setAllFields] = useState<ComparisonUpdateFields>([]);
	const [selectedFields, setSelectedFields] = useState<ComparisonUpdateFields>(
		[],
	);

	useEffect(() => {
		let allFields: ComparisonUpdateFields = [];
		if (isVex) {
			allFields = ['Tags', 'Requirements'];
		} else {
			allFields = [
				'VehicleCategories',
				'Keywords',
				'Categories',
				'DriveVariants',
			];
		}
		setAllFields(allFields);
		setSelectedFields(allFields);
	}, [isVex]);

	const getRefValues = useCallback(
		(input: {
			oldValues: RegulatoryDocumentParagraph;
			newValues: RegulatoryDocumentParagraph;
			fieldType: ComparisonUpdateFieldType;
		}) => {
			const {oldValues, newValues, fieldType} = input;
			const isFieldSelected = selectedFields.indexOf(input.fieldType) !== -1;

			const getMapRef = (
				oldValues: {id: string}[],
				newValues: {id: string}[],
			) => {
				if (isFieldSelected) {
					return concatAndRemoveDuplicates(
						mapToRef(oldValues),
						mapToRef(newValues),
					);
				}
				return mapToRef(newValues);
			};

			let refs: {id: string}[] = [];
			switch (fieldType) {
				case 'VehicleCategories':
					refs = getMapRef(
						oldValues.vehicleCategories,
						newValues.vehicleCategories,
					);
					break;
				case 'Keywords':
					refs = getMapRef(oldValues.keywords, newValues.keywords);
					break;
				case 'Categories':
					refs = getMapRef(oldValues.categories, newValues.categories);
					break;
				case 'DriveVariants':
					refs = getMapRef(oldValues.driveVariants, newValues.driveVariants);
					break;
				default:
					console.error(
						`> ComparisonUpdateField Type ${input.fieldType} is not implemented!`,
					);
			}
			return refs;
		},
		[selectedFields],
	);

	const concatAndRemoveDuplicates = (arr1: any[], arr2: any[]): any[] => {
		return arr1.concat(arr2).reduce((acc, curr) => {
			if (!acc.some((el: {id: string}) => el.id === curr.id)) acc.push(curr);
			return acc;
		}, [] as any[]);
	};

	const getUpdatedComparedItems = (newComparisonItems: IComparedItem[]) => {
		selectedItems.forEach(selectedItem => {
			const paragraph = selectedItem.newParagraph
				?.paragraph as unknown as RegulatoryDocumentParagraph;
			const {id} = paragraph;
			const newComparisonIndex = newComparisonItems.findIndex(
				ci => (ci.newParagraph?.paragraph as any).id === id,
			);
			const newComparisonParagraph = newComparisonItems[newComparisonIndex]
				.newParagraph?.paragraph as any as RegulatoryDocumentParagraph;
			const oldComparisonParagraph = newComparisonItems[newComparisonIndex]
				.oldParagraph?.paragraph as any as RegulatoryDocumentParagraph;
			if (selectedFields.indexOf('Categories') !== -1) {
				newComparisonParagraph.categories = concatAndRemoveDuplicates(
					oldComparisonParagraph.categories,
					newComparisonParagraph.categories,
				);
			}
			if (selectedFields.indexOf('DriveVariants') !== -1) {
				newComparisonParagraph.driveVariants = concatAndRemoveDuplicates(
					oldComparisonParagraph.driveVariants,
					newComparisonParagraph.driveVariants,
				);
			}
			if (selectedFields.indexOf('Keywords') !== -1) {
				newComparisonParagraph.keywords = concatAndRemoveDuplicates(
					oldComparisonParagraph.keywords,
					newComparisonParagraph.keywords,
				);
			}
			if (selectedFields.indexOf('Requirements') !== -1) {
				newComparisonParagraph.requirements = concatAndRemoveDuplicates(
					oldComparisonParagraph.requirements,
					newComparisonParagraph.requirements,
				);
			}
			if (selectedFields.indexOf('Tags') !== -1) {
				newComparisonParagraph.tags = concatAndRemoveDuplicates(
					oldComparisonParagraph.tags,
					newComparisonParagraph.tags,
				);
			}
			if (selectedFields.indexOf('VehicleCategories') !== -1) {
				newComparisonParagraph.vehicleCategories = concatAndRemoveDuplicates(
					oldComparisonParagraph.vehicleCategories,
					newComparisonParagraph.vehicleCategories,
				);
			}
		});
		return newComparisonItems;
	};

	const updateHandler = async (
		newComparisonItems: IComparedItem[],
	): Promise<IComparedItem[]> => {
		await updateParagraphs({
			variables: {
				input: {
					regulatoryDocumentId: newVersion.id,
					paragraphUpdates: selectedItems.reduce((result, item) => {
						const {oldParagraph, newParagraph} = item;
						if (oldParagraph && newParagraph) {
							const oldValues =
								oldParagraph.paragraph as unknown as RegulatoryDocumentParagraph;
							const newValues =
								newParagraph.paragraph as unknown as RegulatoryDocumentParagraph;

							const commonParams = {
								oldValues,
								newValues,
							};
							return result.concat({
								paragraphId: newParagraph.paragraph.id,
								update: {
									vehicleCategoryRefs: getRefValues({
										...commonParams,
										fieldType: 'VehicleCategories',
									}),
									keywordRefs: getRefValues({
										...commonParams,
										fieldType: 'Keywords',
									}),
									driveVariantRefs: getRefValues({
										...commonParams,
										fieldType: 'DriveVariants',
									}),
									categoryRefs: getRefValues({
										...commonParams,
										fieldType: 'Categories',
									}),
									tagRefs: getRefValues({
										...commonParams,
										fieldType: 'Tags',
									}),
								},
							});
						}

						return result;
					}, [] as ParagraphUpdateInput[]),
				},
			},
		});

		const ret = getUpdatedComparedItems(newComparisonItems);

		if (selectedFields.indexOf('Requirements') === -1) {
			return ret;
		}

		const map = await createCloneRequirements(
			data,
			selectedItems,
			createCloneForMerging,
			getInitialVersion,
		);

		await updateParagraphsRequirementsCompareDocument({
			variables: {
				input: {
					regulatoryDocumentId: newVersion.id,
					requirementRefs: selectedItems.map(item =>
						prepareRequirementsIdsForUpdate(item, map),
					),
					paragraphIds: selectedItems.map(item => {
						const {newParagraph} = item;

						return newParagraph?.paragraph.id;
					}),
				},
			},
			refetchQueries: [GetRegulatoryDocumentDetailsDocument],
		});
		return ret;
	};

	const updatedTextItems = useMemo(
		() =>
			allFields.map(sf => {
				return {
					key: sf,
					value: t(sf),
					isSelected: selectedFields.indexOf(sf) !== -1,
				};
			}),
		[allFields, selectedFields, t],
	);

	const selectField = useCallback(
		(field: ComparisonUpdateFieldType) => {
			if (selectedFields.indexOf(field) === -1) {
				setSelectedFields([...selectedFields, field]);
			}
		},
		[selectedFields],
	);

	const unselectField = useCallback(
		(field: ComparisonUpdateFieldType) => {
			const index = selectedFields.indexOf(field);
			if (index !== -1) {
				const newFields = [...selectedFields];
				newFields.splice(index, 1);
				setSelectedFields(newFields);
			}
		},
		[selectedFields],
	);

	return {
		updateHandler,
		updatedTextItems,
		selectField,
		unselectField,
		selectedFields,
	};
};
